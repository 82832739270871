import { useCallback, useRef, useState, useSyncExternalStore } from "react";
export function useSelfRefresh(fn, getServerSnapshot, dependencies) {
    const [listeners] = useState(()=>new Set());
    const snapshot = useRef(null);
    const serverSnapshot = useRef(null);
    return useSyncExternalStore(useCallback((cb)=>{
        listeners.add(cb);
        return ()=>listeners.delete(cb);
    }, []), useCallback(()=>{
        var _snapshot;
        var _current;
        return (_current = (_snapshot = snapshot).current) != null ? _current : _snapshot.current = fn(()=>{
            snapshot.current = null;
            listeners.forEach((cb)=>cb());
        });
    }, dependencies), useCallback(()=>{
        var _serverSnapshot;
        var _current;
        return (_current = (_serverSnapshot = serverSnapshot).current) != null ? _current : _serverSnapshot.current = getServerSnapshot();
    }, []));
}

function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _async_to_generator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
import { navigate } from "bun-react-ssr/router";
import { useEventHandler } from "./useEventHandler";
import { isAndroid } from "react-device-detect";
const api = globalThis;
if (isAndroid) setTimeout(()=>{
    if (embedInjectFix() === 0) {
        const orig = history.replaceState;
        history.replaceState = (state, unused, url)=>{
            if (url) return orig.call(history, state, unused, url);
            const parsed = new URL(location.href);
            parsed.searchParams.set("workaround", Math.random() + "");
            return orig.call(history, state, unused, parsed);
        };
    }
}, 1000);
export const isEmbedded = ()=>!!api.EMBED;
export const embedKeyboardFix = ()=>{
    var _api_EMBED;
    var _api_EMBED_keyboardFix;
    return (_api_EMBED_keyboardFix = (_api_EMBED = api.EMBED) == null ? void 0 : _api_EMBED.keyboardFix) != null ? _api_EMBED_keyboardFix : 0;
};
export const embedAndroidShareFix = ()=>{
    var _api_EMBED;
    var _api_EMBED_androidShareFix;
    return (_api_EMBED_androidShareFix = (_api_EMBED = api.EMBED) == null ? void 0 : _api_EMBED.androidShareFix) != null ? _api_EMBED_androidShareFix : 0;
};
export const embedInjectFix = ()=>{
    var _api_EMBED;
    var _api_EMBED_injectFix;
    return api.EMBED ? (_api_EMBED_injectFix = (_api_EMBED = api.EMBED) == null ? void 0 : _api_EMBED.injectFix) != null ? _api_EMBED_injectFix : 0 : 1;
};
export function reload() {
    location.reload();
}
export function useEmbedLink(url, opt) {
    return useEventHandler(()=>{
        embedNavigate(url, opt);
    });
}
export function embedNavigate(url, opt) {
    var _api_EMBED;
    if ((!(opt == null ? void 0 : opt.allowSeamless) || !toggleSeamless(true)) && ((_api_EMBED = api.EMBED) == null ? void 0 : _api_EMBED.navigate)) {
        api.EMBED.navigate(url);
        return;
    }
    navigate(url, opt);
}
export function embedBack() {
    var _api_EMBED;
    if ((_api_EMBED = api.EMBED) == null ? void 0 : _api_EMBED.back) {
        api.EMBED.back();
        return;
    }
    if (window.history.length > 1) window.history.back();
    else navigate("/", {
        replace: true
    });
}
export function embedLogin() {
    var _api_EMBED;
    if ((_api_EMBED = api.EMBED) == null ? void 0 : _api_EMBED.login) {
        api.EMBED.login();
        return true;
    }
    return false;
}
export function openWallet() {
    var _api_EMBED;
    if ((_api_EMBED = api.EMBED) == null ? void 0 : _api_EMBED.openWallet) {
        api.EMBED.openWallet();
        return true;
    }
    return false;
}
export function openInAppLink(link) {
    var _api_EMBED;
    if ((_api_EMBED = api.EMBED) == null ? void 0 : _api_EMBED.openInAppLink) {
        api.EMBED.openInAppLink(link);
        return true;
    }
    return false;
}
export function openDeepLink(link) {
    var _api_EMBED;
    if ((_api_EMBED = api.EMBED) == null ? void 0 : _api_EMBED.openDeepLink) {
        api.EMBED.openDeepLink(link);
        return true;
    }
    return false;
}
export function toggleSeamless(seamless) {
    var _api_EMBED;
    if ((_api_EMBED = api.EMBED) == null ? void 0 : _api_EMBED.toggleSeamless) {
        api.EMBED.toggleSeamless(seamless);
        return true;
    }
    return false;
}
export function shareable() {
    var _api_EMBED;
    return ((_api_EMBED = api.EMBED) == null ? void 0 : _api_EMBED.share) || navigator.share;
}
export function share({ title = document.title, url = location.href, image } = {}) {
    var _api_EMBED;
    if ((_api_EMBED = api.EMBED) == null ? void 0 : _api_EMBED.share) {
        api.EMBED.share(title, url, image);
        return;
    }
    if (!navigator.share) {
        return;
    }
    if (image) {
        _async_to_generator(function*() {
            const data = yield fetch(image);
            const blob = yield data.blob();
            const file = new File([
                blob
            ], title + ".png");
            navigator.share({
                title,
                url,
                files: [
                    file
                ]
            });
        })().catch(console.error);
        return;
    }
    navigator.share({
        title,
        url
    });
}

import { createPromiseWithState } from "$frontend/utils/promise";
import { ClientOnlyError } from "bun-react-ssr/client";
import { createContext, useContext, useMemo } from "react";
export const LogtoContext = createContext({
    refresh () {}
});
export function useLogto() {
    return useContext(LogtoContext);
}
export const AuthPromiseContext = createContext(createPromiseWithState());
export const AuthContext = createContext(undefined);
export function useAuthPromise() {
    if (typeof window === "undefined") throw new ClientOnlyError();
    return useContext(AuthPromiseContext);
}
export function useAuth() {
    return useContext(AuthContext);
}
export function useUserId() {
    var _useUserInfo;
    return (_useUserInfo = useUserInfo()) == null ? void 0 : _useUserInfo.id;
}
export function useUserInfo() {
    const token = useAuth();
    return useMemo(()=>{
        try {
            if (typeof token === "string" && token) {
                const parsed = JSON.parse(atob(token.split(".")[1]));
                return {
                    id: parsed.sub,
                    roles: parsed.role_names
                };
            } else {
                return undefined;
            }
        } catch (e) {
            return undefined;
        }
    }, [
        token
    ]);
}

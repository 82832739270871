import { useCallback, useEffect, useRef, useState } from "react";
export function usePreMountState(initial) {
    const initialRef = useRef(initial);
    const mountState = useRef(false);
    const [state, setState] = useState(initialRef.current);
    useEffect(()=>{
        setState(initialRef.current);
        mountState.current = true;
        return ()=>{
            mountState.current = false;
        };
    }, []);
    const update = useCallback((action)=>{
        if (mountState.current) setState(action);
        else initialRef.current = action instanceof Function ? action(initialRef.current) : action;
    }, []);
    return [
        state,
        update
    ];
}
